import { useState, useEffect } from 'react';
import * as Yup from 'yup';
// form
import {
  loadCaptchaEnginge,
  //   LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
// @mui
import { Stack, IconButton, InputAdornment, Alert, Box, Typography, Container } from '@mui/material';
// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
// components
import Iconify from '../../../components/Iconify';
import { FormProvider, RHFPhonenumber, RHFTextField } from '../../../components/hook-form';
import { GoldBorderLoadingButton } from '../../../components/nav-section/vertical/style';
import { useDispatch } from '../../../redux/store';
import { closeRegister } from '../../../redux/slices/authPages';
import useLocales from '../../../hooks/useLocales';
import RHFDatePicker from '../../../components/hook-form/RHFDatePicker';

// ----------------------------------------------------------------------

export default function RegisterForm() {
  const { register } = useAuth();

  const { translate } = useLocales();

  const isMountedRef = useIsMountedRef();

  const dispatch = useDispatch();

  const [showPassword, setShowPassword] = useState(false);

  const RegisterSchema = Yup.object().shape({
    username: Yup.string().required(translate('x_is_required', { x: translate('full_name') })),
    profileNoHp: Yup.string().required(translate('x_is_required', { x: translate('phone_number') })),
    email: Yup.string()
      .email(translate('invalid_x', { x: translate('email') }))
      .required(translate('x_is_required', { x: translate('email') })),
    fullName: Yup.string().required(translate('x_is_required', { x: translate('full_name') })),
    password: Yup.string().required(translate('x_is_required', { x: translate('password') })),
    birthday: Yup.string().required(translate('x_is_required', { x: translate('birthday') })),
    captcha: Yup.string().required(translate('x_is_required', { x: translate('captcha') })),
  });

  // useEffect(() => {
  //   return () => localStorage.removeItem('referralCode');
  // }, []);

  const defaultValues = {
    username: '',
    profileNoHp: '',
    email: '',
    fullName: '',
    password: '',
    referralCode: localStorage.getItem('referralCode') || '',
    birthday: '',
    captcha: '',
  };

  const methods = useForm({
    resolver: yupResolver(RegisterSchema),
    defaultValues,
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    try {
      const newData = JSON.parse(JSON.stringify(data));
      if (!validateCaptcha(data?.captcha)) throw new Error(translate('invalid_captcha'));

      delete newData.captcha;
      const formattedDob = moment(newData?.birthday).format('YYYY-MM-DD');
      await register({
        ...newData,
        birthday: formattedDob,
      });
      reset();
      dispatch(closeRegister());
    } catch (error) {
      console.error(error);
      // if (isMountedRef.current) {
      setError('afterSubmit', { ...error, message: translate(error.message) });
      // }
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(4);
  }, []);

  return (
    <Container maxWidth="xs">
      <Box sx={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
        {/* <Box sx={{ width: '65%' }}> */}
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

            <RHFTextField name="username" label={translate('username')} />

            <RHFTextField name="fullName" label={translate('full_name')} />

            <RHFPhonenumber name="profileNoHp" label={translate('phone_number')} />

            <RHFTextField name="email" label={translate('email')} />

            <RHFDatePicker name="birthday" label={translate('birthday')} />
            <Typography style={{ fontSize: '.75rem', color: '#c9c9c9', fontWeight: 500 }}>
              {translate('birthday_bonus')}
            </Typography>

            <RHFTextField
              name="password"
              label={translate('password')}
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={() => setShowPassword(!showPassword)} tabIndex={-1}>
                      <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <RHFTextField
              name="referralCode"
              disabled={localStorage?.getItem('referralCode')}
              label={translate('referral_code')}
            />

            <RHFTextField
              name="captcha"
              label={translate('captcha')}
              placeholder={translate('captcha')}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <LoadCanvasTemplateNoReload />
                  </InputAdornment>
                ),
              }}
            />

            <GoldBorderLoadingButton fullWidth variant="outlined" size="large" type="submit" loading={isSubmitting}>
              {translate('register')}
            </GoldBorderLoadingButton>
          </Stack>
        </FormProvider>
        {/* </Box> */}
      </Box>
    </Container>
  );
}
